export const APP_ID_ROWER = 1;
export const APP_ID_ROWER_GROUP = 17;

export const formatAppData = (app: any) => {
  if(app) {
    let res = [];
    for (let i = 0; i < app.length; i++) {
      const item = app[i];
      if(item.image) {
        res.push({
          ...item,
          id: item.id === APP_ID_ROWER ? APP_ID_ROWER_GROUP : item.id
        })
      }
    }
    return res;
  }
  return null
}