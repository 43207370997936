import * as React from "react";
import {
    adminReducer,
    subscriptionReducer,
    globalReducer,
    machineReducer,
    userReducer,
    experienceReducer,
    experienceCategoryReducer,
    blogReducer,
    postReducer,
    dealerLocationReducer,
    challengeReducer,
    gameLevelsReducer,
    supportCategoryReducer,
    supportPostReducer,
} from "./reducers";
import { workoutReducer } from './reducers/workouts/workout.reducer';
import Action from "./actions";

type AppContextProps = {
    global: any;
    globalDispatch: React.Dispatch<any>;
    admin: any;
    adminDispatch: React.Dispatch<any>;
    subscriptionStore: any;
    subscriptionDispatch: React.Dispatch<any>;
    machineStore: any;
    machineDispatch: React.Dispatch<any>;
    userStore: any;
    userDispatch: React.Dispatch<any>;
    experienceStore: any;
    experienceDispatch: React.Dispatch<any>;
    experienceCategoryStore: any;
    experienceCategoryDispatch: React.Dispatch<any>;
    blogStore: any;
    blogDispatch: React.Dispatch<any>;
    postStore: any;
    postDispatch: React.Dispatch<any>;
    dealerLocationStore: any;
    dealerLocationDispatch: React.Dispatch<any>;
    challengeStore: any;
    challengeDispatch: React.Dispatch<any>;
    gameLevelsStore: any;
    gameLevelsDispatch: React.Dispatch<any>;
    workoutState: any;
    workoutDispatch: React.Dispatch<any>;
    supportCategoryStore: any;
    supportCategoryDispatch: React.Dispatch<any>;
    supportPostStore: any;
    supportPostDispatch: React.Dispatch<any>;
};

const AdminAppContext: React.Context<AppContextProps> = React.createContext(
    {} as any
);

const AdminAppContextProvider: React.FunctionComponent = ({ children }) => {
    const [admin, adminDispatch] = React.useReducer(
        adminReducer.reducer,
        adminReducer.initState
    );
    const [global, globalDispatch] = React.useReducer(
        globalReducer.reducer,
        globalReducer.initState
    );
    const [subscriptionStore, subscriptionDispatch] = React.useReducer(
        subscriptionReducer.reducer,
        subscriptionReducer.initState
    );
    const [machineStore, machineDispatch] = React.useReducer(
        machineReducer.reducer,
        machineReducer.initState
    );
    const [userStore, userDispatch] = React.useReducer(
        userReducer.reducer,
        userReducer.initState
    );
    const [experienceStore, experienceDispatch] = React.useReducer(
        experienceReducer.reducer,
        experienceReducer.initState
    );
    const [
        experienceCategoryStore,
        experienceCategoryDispatch,
    ] = React.useReducer(
        experienceCategoryReducer.reducer,
        experienceCategoryReducer.initState
    );
    const [blogStore, blogDispatch] = React.useReducer(
        blogReducer.reducer,
        blogReducer.initState
    );
    const [postStore, postDispatch] = React.useReducer(
        postReducer.reducer,
        postReducer.initState
    );
    const [workoutState, workoutDispatch] = React.useReducer(
        workoutReducer.reducer,
        workoutReducer.initialState);
    const [dealerLocationStore, dealerLocationDispatch] = React.useReducer(
        dealerLocationReducer.reducer,
        dealerLocationReducer.initState
    )
    const [challengeStore, challengeDispatch] = React.useReducer(
        challengeReducer.reducer,
        challengeReducer.initState
    );
    const [gameLevelsStore, gameLevelsDispatch] = React.useReducer(
        gameLevelsReducer.reducer,
        gameLevelsReducer.initState
    );
    const [supportCategoryStore, supportCategoryDispatch] = React.useReducer(
        supportCategoryReducer.reducer,
        supportCategoryReducer.initState
    )
    const [supportPostStore, supportPostDispatch] = React.useReducer(
        supportPostReducer.reducer,
        supportPostReducer.initState
    )

    return (
        <AdminAppContext.Provider
            value={{
                global,
                globalDispatch,
                admin,
                adminDispatch,
                subscriptionStore,
                subscriptionDispatch,
                machineStore,
                machineDispatch,
                userStore,
                userDispatch,
                experienceStore,
                experienceDispatch,
                experienceCategoryStore,
                experienceCategoryDispatch,
                blogStore,
                blogDispatch,
                postStore,
                postDispatch,
                dealerLocationStore,
                dealerLocationDispatch,
                challengeStore,
                challengeDispatch,
                gameLevelsStore,
                gameLevelsDispatch,
                workoutState, workoutDispatch,
                supportCategoryStore, supportCategoryDispatch,
                supportPostStore, supportPostDispatch,
            }}
        >
            {children}
        </AdminAppContext.Provider>
    );
};
export { AdminAppContextProvider, AdminAppContext, Action };
